<script>
import Layout from "../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { fetchSettings } from "@/api/common";
import { required, numeric } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Paramètres techniques",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  import: { fetchSettings },

  mounted() {
    this.populateCompanySettings();
  },

  validations: {
    config: {
      email: {
        mail_host: { required },
        mail_port: { required, numeric },
        mail_username: { required },
        mail_password: { required },
        mail_encryption: { required },
        mail_from_address: { required },
        mail_from_name: { required },
      },

      // BKP
      bkp: {
        bkp_method: { required },
        bkp_server: { required },
        bkp_frequency: { required },
        bkp_month_day: { required },
        bkp_weekly_day: { required },
        bkp_time: { required },
      },

      // "rd party"
      oracle: {
        rhsm_orcl_conn_name: { required },
        rhsm_orcl_conn_host: { required },
        rhsm_orcl_tns: { required },
        rhsm_orcl_username: { required },
        rhsm_orcl_password: { required },
        rhsm_orcl_schema: { required },
        rhsm_orcl_tableName: { required },

        restReader_orcl_conn_name: { required },
        restReader_orcl_tns: { required },
        restReader_orcl_host: { required },
        restReader_orcl_username: { required },
        restReader_orcl_password: { required },
        restReader_orcl_schema: { required },
        restReader_orcl_tableName: { required },
      },
      // AD
      ldap: {
        ldap_con_name: { required },
        ldap_hostname: { required },
        ldap_port: { required },
        ldap_dn: { required },
        ldap_username: { required },
        ldap_password: { required },
      },
    },
  },

  methods: {
    populateCompanySettings() {
      fetchSettings()
        .then(({ data }) => {
          var configs = data.data;
          this.config.email.mail_host = configs.mail_host;
          this.config.email.mail_port = configs.mail_port;
          this.config.email.mail_username = configs.mail_username;
          this.config.email.mail_password = configs.mail_password;
          this.config.email.mail_encryption = configs.mail_encryption;
          this.config.email.mail_from_address = configs.mail_from_address;
          this.config.email.mail_from_name = configs.mail_from_name;

          // bkp
          this.config.bkp.bkp_method = configs.bkp_method;
          this.config.bkp.bkp_server = configs.bkp_server;
          this.config.bkp.bkp_frequency = configs.bkp_frequency;
          this.config.bkp.bkp_month_day = configs.bkp_month_day;
          this.config.bkp.bkp_weekly_day = configs.bkp_weekly_day;
          this.config.bkp.bkp_time = configs.bkp_time;

          // 3RD PARTY
          this.config.oracle.rhsm_orcl_conn_name = configs.rhsm_orcl_conn_name;
          this.config.oracle.rhsm_orcl_conn_host = configs.rhsm_orcl_conn_host;
          this.config.oracle.rhsm_orcl_tns = configs.rhsm_orcl_tns;
          this.config.oracle.rhsm_orcl_username = configs.rhsm_orcl_username;
          this.config.oracle.rhsm_orcl_password = configs.rhsm_orcl_password;
          this.config.oracle.rhsm_orcl_schema = configs.rhsm_orcl_schema;
          this.config.oracle.rhsm_orcl_tableName = configs.rhsm_orcl_tableName;

          this.config.oracle.restReader_orcl_conn_name = configs.restReader_orcl_conn_name;
          this.config.oracle.restReader_orcl_host = configs.restReader_orcl_host;
          this.config.oracle.restReader_orcl_tns = configs.restReader_orcl_tns;
          this.config.oracle.restReader_orcl_username = configs.restReader_orcl_username;
          this.config.oracle.restReader_orcl_password = configs.restReader_orcl_password;
          this.config.oracle.restReader_orcl_schema = configs.restReader_orcl_schema;
          this.config.oracle.restReader_orcl_tableName = configs.restReader_orcl_tableName;

          this.config.ldap.ldap_con_name = configs.ldap_con_name;
          this.config.ldap.ldap_hostname = configs.ldap_hostname;
          this.config.ldap.ldap_port = configs.ldap_port;
          this.config.ldap.ldap_dn = configs.ldap_dn;
          this.config.ldap.ldap_username = configs.ldap_username;
          this.config.ldap.ldap_password = configs.ldap_password;
          this.config.ldap.ldap_use_ad_to_auth_users =
            Boolean(configs.ldap_use_ad_to_auth_users);
        })
        .catch((err) => {
          this.$toast.error(err.message);
        })
        .finally(() => {});
    },

    submitEmailSettings() {
      this.submitted = true;
      this.$v.config.email.$touch();
      if (!this.$v.config.email.$invalid) {
        var loader = this.$loading.show();
        this.$http
          .post("/system/settings/store", this.config.email)
          .then((res) => {
            var status = res.data.original.status;
            loader.hide();
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this.populateCompanySettings();
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },

    submitBkpSettings() {
      this.submitted = true;
      this.$v.config.bkp.$touch();
      if (!this.$v.config.bkp.$invalid) {
        var loader = this.$loading.show();
        this.$http
          .post("/system/settings/store", this.config.bkp)
          .then((res) => {
            var status = res.data.original.status;
            loader.hide();
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this.populateCompanySettings();
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },

    submitLdapSettings() {
      this.submitted = true;
      this.$v.config.ldap.$touch();
      if (!this.$v.config.ldap.$invalid) {
        var loader = this.$loading.show();
        this.$http
          .post("/system/settings/store", this.config.ldap)
          .then((res) => {
            var status = res.data.original.status;
            loader.hide();
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this.populateCompanySettings();
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },

    testLdapConnection(){
      this.submitted = true;
      this.$v.config.ldap.$touch();
      if (!this.$v.config.ldap.$invalid) {
        var loader = this.$loading.show();
        this.$http
          .post("/system/settings/testLdapConnection", this.config.ldap)
          .then((res) => {
            var status = res.data.original.status;
            loader.hide();
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },

    syncLdapUsers(){
        Swal.fire({
        title: "Cette opération peut prendre du temps?",
        text: "êtes-vous sûr de vouloir continuer ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Non",
        confirmButtonText: "Oui!",
      }).then((result) => {
        if (result.value) {
          var loader = this.$loading.show();
          this.$http
            .post("/system/settings/syncLdapUsers")
            .then((res) => {
              var status = res.data.original.status;
              loader.hide();
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });      
    },

    submitOracleSettings() {
      this.submitted = true;
      this.$v.config.oracle.$touch();
      if (!this.$v.config.oracle.$invalid) {
        var loader = this.$loading.show();
        this.$http
          .post("/system/settings/store", this.config.oracle)
          .then((res) => {
            var status = res.data.original.status;
            loader.hide();
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this.populateCompanySettings();
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },

    testOracleReshumConn() {
      this.submitted = true;
      this.$v.config.oracle.$touch();
      if (!this.$v.config.oracle.$invalid) {
        var loader = this.$loading.show();
        this.$http
          .post("/system/settings/testOdbConnection", this.config.oracle)
          .then((res) => {
            var status = res.data.original.status;
            loader.hide();
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },

    testOracleAccessConn() {
      this.submitted = true;
      this.$v.config.oracle.$touch();
      if (!this.$v.config.oracle.$invalid) {
        var loader = this.$loading.show();
        this.$http
          .post("/system/settings/testOdbRestConnection", this.config.oracle)
          .then((res) => {
            var status = res.data.original.status;
            loader.hide();
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
      } else {
        alert("zdfaefea");
      }
    },

    syncEmployeesFromResHume(){
      var loader = this.$loading.show();
      this.$http
          .post("/base/employees/syncFrom3rdParty")
          .then((res) => {
            var status = res.data.original.status;
            loader.hide();
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
    },
  },
  data() {
    return {
      title: "Paramètres techniques",
      week_days: [
        "Samedi",
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
      ],
      submitted: false,
      config: {
        email: {
          mail_host: "",
          mail_port: "",
          mail_username: "",
          mail_password: "",
          mail_encryption: "",
          mail_from_address: "",
          mail_from_name: "",
        },

        // BKP
        bkp: {
          bkp_method: "",
          bkp_server: "",
          bkp_frequency: "",
          bkp_month_day: "",
          bkp_weekly_day: "",
          bkp_time: "",
        },

        // "rd party"
        oracle: {
          rhsm_orcl_conn_name: "",
          rhsm_orcl_conn_host: "",
          rhsm_orcl_tns: "",
          rhsm_orcl_username: "",
          rhsm_orcl_password: "",
          rhsm_orcl_schema: "",
          rhsm_orcl_tableName: "",
          restReader_orcl_conn_name: "",
          restReader_orcl_host: "",
          restReader_orcl_tns: "",
          restReader_orcl_username: "",
          restReader_orcl_password: "",
          restReader_orcl_schema: "",
          restReader_orcl_tableName: "",
        },

        ldap: {
          ldap_con_name: "",
          ldap_hostname: "",
          ldap_port: "",
          ldap_dn: "",
          ldap_username: "",
          ldap_password: "",
          ldap_use_ad_to_auth_users: "",
        },
      },
      items: [
        {
          text: "Paramètres",
          to: { name: "base.settings.index" },
        },
        {
          text: "Paramètres techniques",
          active: false,
        },
      ],
    };
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-tabs
              pills
              vertical
              nav-class="p-0"
              nav-wrapper-class="col-sm-2"
              content-class="pt-0 px-2 text-muted"
            >
              <b-tab title="Email" active title-item-class="mb-2">
                <!-- Requêtes Maintenance -->
                <form @submit.prevent="submitEmailSettings">
                  <div class="row">
                    <div class="col-lg-12 ">
                      <h4 class="card-title mb-4">Email</h4>
                      <div class="row mb-3">
                        <!-- <h6>Référence de document</h6> <br> -->
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Serveur MAIL</label>
                            <input
                              type="text"
                              v-model="config.email.mail_host"
                              :class="{
                                'is-invalid':
                                  submitted && $v.config.email.mail_host.$error,
                              }"
                              class="form-control"
                              name=""
                              id=""
                            />
                            <div
                              v-if="
                                submitted && $v.config.email.mail_host.$error
                              "
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.config.email.mail_host.required"
                                >Champ Obligatoire.</span
                              >
                              <br />
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Port SMTP</label>
                            <input
                              type="text"
                              v-model="config.email.mail_port"
                              :class="{
                                'is-invalid':
                                  submitted && $v.config.email.mail_port.$error,
                              }"
                              class="form-control"
                              name=""
                              id=""
                            />
                            <div
                              v-if="
                                submitted && $v.config.email.mail_port.$error
                              "
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.config.email.mail_port.required"
                                >Champ Obligatoire.</span
                              >
                              <span v-if="!$v.config.email.mail_port.numeric"
                                >Champ Invalide.</span
                              >
                              <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Encryption</label>
                            <input
                              type="text"
                              v-model="config.email.mail_encryption"
                              class="form-control"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.config.email.mail_encryption.$error,
                              }"
                              name=""
                              id=""
                            />
                            <div
                              v-if="
                                submitted &&
                                  $v.config.email.mail_encryption.$error
                              "
                              class="invalid-feedback"
                            >
                              <span
                                v-if="!$v.config.email.mail_encryption.required"
                                >Champ Obligatoire.</span
                              >
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row  mb-3">
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Nom d'utilisateur</label>
                            <input
                              type="text"
                              v-model="config.email.mail_username"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.config.email.mail_username.$error,
                              }"
                              class="form-control"
                              name=""
                              id=""
                            />
                            <div
                              v-if="
                                submitted &&
                                  $v.config.email.mail_username.$error
                              "
                              class="invalid-feedback"
                            >
                              <span
                                v-if="!$v.config.email.mail_username.required"
                                >Champ Obligatoire.</span
                              >
                              <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Mot de passe</label>
                            <input
                              type="password"
                              v-model="config.email.mail_password"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.config.email.mail_password.$error,
                              }"
                              autocomplete="false"
                              class="form-control"
                              name=""
                              id=""
                            />
                            <div
                              v-if="
                                submitted &&
                                  $v.config.email.mail_password.$error
                              "
                              class="invalid-feedback"
                            >
                              <span
                                v-if="!$v.config.email.mail_password.required"
                                >Champ Obligatoire.</span
                              >
                              <br />
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Adresse email d'Expéditeur</label>
                            <input
                              type="text"
                              v-model="config.email.mail_from_address"
                              class="form-control"
                              name=""
                              id=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Nom d'Expéditeur</label>
                            <input
                              type="text"
                              v-model="config.email.mail_from_name"
                              class="form-control"
                              name=""
                              id=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-md-12">
                      <button class="btn btn-primary float-right">
                        <i class="fas fa-save"></i> Enregistrer
                      </button>
                    </div>
                  </div>
                </form>
              </b-tab>

              <b-tab title="SMS" title-item-class="mb-2">
                <div class="row">
                  <div class="col-lg-12 ">
                    <h4 class="card-title mb-4">Notifications SMS</h4>
                    <div class="row mb-3">
                      <!-- <h6>Référence de document</h6> <br> -->
                      <div class="col-md-4">
                        <div class="form-grou">
                          <label for="">Titre</label>
                          <input
                            type="text"
                            class="form-control"
                            name=""
                            id=""
                          />
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-grou">
                          <label for="">API Endpoint</label>
                          <input
                            type="text"
                            class="form-control"
                            name=""
                            id=""
                          />
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-grou">
                          <label for="">Clé API</label>
                          <input
                            type="text"
                            class="form-control"
                            name=""
                            id=""
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-grou">
                          <label for="">Expéditeur</label>
                          <input
                            type="text"
                            class="form-control"
                            name=""
                            id=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-12">
                    <button class="btn btn-primary float-right">
                      <i class="fas fa-save"></i> Enregistrer
                    </button>
                  </div>
                </div>
              </b-tab>

              <b-tab title="Active Directory" title-item-class="mb-2">
                <form @submit.prevent="submitLdapSettings">
                  <div class="row">
                    <div class="col-lg-12 ">
                      <h4 class="card-title mb-4">Active Directory</h4>
                      <div class="row mb-3">
                        <!-- <h6>Référence de document</h6> <br> -->
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Nom de connection</label>
                            <input
                              type="text"
                              v-model="config.ldap.ldap_con_name"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.config.ldap.ldap_con_name.$error,
                              }"
                              class="form-control"
                              name=""
                              id=""
                            />
                            <div
                              v-if="
                                submitted && $v.config.ldap.ldap_con_name.$error
                              "
                              class="invalid-feedback"
                            >
                              <span
                                v-if="!$v.config.ldap.ldap_con_name.required"
                                >Champ Obligatoire.</span
                              >
                              <br />
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Hostname</label>
                            <input
                              type="text"
                              v-model="config.ldap.ldap_hostname"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.config.ldap.ldap_hostname.$error,
                              }"
                              class="form-control"
                              name=""
                              id=""
                            />
                            <div
                              v-if="
                                submitted && $v.config.ldap.ldap_hostname.$error
                              "
                              class="invalid-feedback"
                            >
                              <span
                                v-if="!$v.config.ldap.ldap_hostname.required"
                                >Champ Obligatoire.</span
                              >
                              <span v-if="!$v.config.ldap.ldap_hostname.numeric"
                                >Champ Invalide.</span
                              >
                              <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Port</label>
                            <input
                              type="text"
                              v-model="config.ldap.ldap_port"
                              class="form-control"
                              :class="{
                                'is-invalid':
                                  submitted && $v.config.ldap.ldap_port.$error,
                              }"
                              name=""
                              id=""
                            />
                            <div
                              v-if="
                                submitted && $v.config.ldap.ldap_port.$error
                              "
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.config.ldap.ldap_port.required"
                                >Champ Obligatoire.</span
                              >
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row  mb-3">
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Domain name</label>
                            <input
                              type="text"
                              v-model="config.ldap.ldap_dn"
                              :class="{
                                'is-invalid':
                                  submitted && $v.config.ldap.ldap_dn.$error,
                              }"
                              class="form-control"
                              name=""
                              id=""
                            />
                            <div
                              v-if="submitted && $v.config.ldap.ldap_dn.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.config.ldap.ldap_dn.required"
                                >Champ Obligatoire.</span
                              >
                              <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Username</label>
                            <input
                              type="text"
                              v-model="config.ldap.ldap_username"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.config.ldap.ldap_username.$error,
                              }"
                              autocomplete="false"
                              class="form-control"
                              name=""
                              id=""
                            />
                            <div
                              v-if="
                                submitted && $v.config.ldap.ldap_username.$error
                              "
                              class="invalid-feedback"
                            >
                              <span
                                v-if="!$v.config.ldap.ldap_username.required"
                                >Champ Obligatoire.</span
                              >
                              <br />
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Password</label>
                            <input
                              type="password"
                              v-model="config.ldap.ldap_password"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.config.ldap.ldap_password.$error,
                              }"
                              class="form-control"
                              name=""
                              id=""
                            />
                            <div
                              v-if="
                                submitted && $v.config.ldap.ldap_password.$error
                              "
                              class="invalid-feedback"
                            >
                              <span
                                v-if="!$v.config.ldap.ldap_password.required"
                                >Champ Obligatoire.</span
                              >
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <b-form-checkbox
                            v-model="config.ldap.ldap_use_ad_to_auth_users"
                            switch
                            class="mb-1"
                          >
                            <label
                              >Utiliser AD pour authentifier les
                              utilisateurs.</label
                            >
                          </b-form-checkbox>
                        </div>

                        <div class="col-md-4 mt-">
                          <a href="javascript:void(0)" @click.prevent="testLdapConnection">Tester la connexion</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-md-12">
                      <button class="btn btn-primary float-right">
                        <i class="fas fa-save"></i> Enregistrer
                      </button>
                      <button @click.prevent="syncLdapUsers" class="btn btn-success float-right mr-2">
                        <i class="fas fa-users"></i> Synchroniser les utilisateurs
                      </button>
                    </div>
                  </div>
                </form>
              </b-tab>

              <b-tab title="Sauvegarde des données" title-item-class="mb-2">
                <form @submit.prevent="submitBkpSettings">
                  <div class="row">
                    <div class="col-lg-12 ">
                      <h4 class="card-title mb-4">Sauvegarde des données</h4>
                      <div class="row">
                        <!-- <h6>Référence de document</h6> <br> -->
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Méthode de sauvegarde</label>
                            <select
                              v-model="config.bkp.bkp_method"
                              name=""
                              class="form-control"
                              id=""
                            >
                              <option value="manual">Manuelle</option>
                              <option value="auto">Automatique</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Machine de savegarde</label>
                            <input
                              type="text"
                              v-model="config.bkp.bkp_server"
                              class="form-control"
                              name=""
                              id=""
                            />
                          </div>
                        </div>
                        <div
                          class="col-md-4"
                          v-if="config.bkp.bkp_method == 'auto'"
                        >
                          <div class="form-grou">
                            <label for="">Fréquence de savegarde</label>
                            <select
                              v-model="config.bkp.bkp_frequency"
                              name=""
                              class="form-control"
                              id=""
                            >
                              <option value="daily">Quotidienne</option>
                              <option value="weekly">Hebdomadaire</option>
                              <option value="monthly">Mensuelle</option>
                            </select>
                          </div>
                        </div>
                        <div
                          class="col-md-2"
                          v-if="
                            config.bkp.bkp_frequency == 'monthly' &&
                              config.bkp.bkp_method == 'auto'
                          "
                        >
                          <div class="form-grou">
                            <label for="">Date de sauvegarde</label>
                            <select
                              v-model="config.bkp.bkp_month_day"
                              class="form-control"
                              name=""
                              id=""
                            >
                              <option v-for="date in 31" v-bind:key="date">{{
                                date
                              }}</option>
                            </select>
                          </div>
                        </div>
                        <div
                          class="col-md-2"
                          v-if="
                            config.bkp.bkp_frequency == 'weekly' &&
                              config.bkp.bkp_method == 'auto'
                          "
                        >
                          <div class="form-grou">
                            <label for="">Jour de sauvegarde</label>
                            <select
                              v-model="config.bkp.bkp_weekly_day"
                              class="form-control"
                              name=""
                              id=""
                            >
                              <option
                                v-for="date in week_days"
                                v-bind:key="date"
                                >{{ date }}</option
                              >
                            </select>
                          </div>
                        </div>
                        <div
                          class="col-md-2"
                          v-if="config.bkp.bkp_method == 'auto'"
                        >
                          <div class="form-grou">
                            <label for="">Heure de sauvegarde</label>
                            <input
                              type="time"
                              v-model="config.bkp_time"
                              class="form-control"
                              name=""
                              id=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <!-- Ordre de maintenance Maintenance -->
                  <div class="row">
                    <div class="col-md-12">
                      <button class="btn btn-primary float-right">
                        <i class="fas fa-save"></i> Enregistrer
                      </button>
                    </div>
                  </div>
                </form>
              </b-tab>
              <b-tab title="Applications externes">
                <b-card-text>
                  <form @submit.prevent="submitOracleSettings">
                    <div class="row">
                      <div class="col-lg-12 ">
                        <h4 class="card-title mb-4">Rhésume (sous Oracle)</h4>
                        <div class="row mb-3">
                          <div class="col-md-4  mb-3">
                            <div class="form-grou">
                              <label for="">Nom de connection</label>
                              <input
                                type="text"
                                v-model="config.oracle.rhsm_orcl_conn_name"
                                class="form-control"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.config.oracle.rhsm_orcl_conn_name.$error,
                                }"
                                name=""
                                id=""
                              />
                              <div
                                v-if="
                                  submitted &&
                                    $v.config.oracle.rhsm_orcl_conn_name.$error
                                "
                                class="invalid-feedback"
                              >
                                <span
                                  v-if="
                                    !$v.config.oracle.rhsm_orcl_conn_name
                                      .required
                                  "
                                  >Champ Obligatoire.</span
                                >
                                <br />
                              </div>
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-grou">
                              <label for="">Serveur</label>
                              <input
                                type="text"
                                v-model="config.oracle.rhsm_orcl_conn_host"
                                class="form-control"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.config.oracle.rhsm_orcl_conn_host.$error,
                                }"
                                name=""
                                id=""
                              />
                              <div
                                v-if="
                                  submitted &&
                                    $v.config.oracle.rhsm_orcl_conn_host.$error
                                "
                                class="invalid-feedback"
                              >
                                <span
                                  v-if="
                                    !$v.config.oracle.rhsm_orcl_conn_host
                                      .required
                                  "
                                  >Champ Obligatoire.</span
                                >
                                <br />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-grou">
                              <label for="">TNS</label>
                              <input
                                type="text"
                                v-model="config.oracle.rhsm_orcl_tns"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.config.oracle.rhsm_orcl_tns.$error,
                                }"
                                class="form-control"
                                name=""
                                id=""
                              />
                              <div
                                v-if="
                                  submitted &&
                                    $v.config.oracle.rhsm_orcl_tns.$error
                                "
                                class="invalid-feedback"
                              >
                                <span
                                  v-if="
                                    !$v.config.oracle.rhsm_orcl_tns.required
                                  "
                                  >Champ Obligatoire.</span
                                >
                                <br />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-grou">
                              <label for="">Username</label>
                              <input
                                type="text"
                                v-model="config.oracle.rhsm_orcl_username"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.config.oracle.rhsm_orcl_username.$error,
                                }"
                                class="form-control"
                                name=""
                                id=""
                              />
                              <div
                                v-if="
                                  submitted &&
                                    $v.config.oracle.rhsm_orcl_username.$error
                                "
                                class="invalid-feedback"
                              >
                                <span
                                  v-if="
                                    !$v.config.oracle.rhsm_orcl_username
                                      .required
                                  "
                                  >Champ Obligatoire.</span
                                >
                                <br />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-grou">
                              <label for="">Password</label>
                              <input
                                type="password"
                                v-model="config.oracle.rhsm_orcl_password"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.config.oracle.rhsm_orcl_password.$error,
                                }"
                                class="form-control"
                                name=""
                                id=""
                              />
                              <div
                                v-if="
                                  submitted &&
                                    $v.config.oracle.rhsm_orcl_password.$error
                                "
                                class="invalid-feedback"
                              >
                                <span
                                  v-if="
                                    !$v.config.oracle.rhsm_orcl_password
                                      .required
                                  "
                                  >Champ Obligatoire.</span
                                >
                                <br />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-grou">
                              <label for="">Schema</label>
                              <input
                                type="text"
                                v-model="config.oracle.rhsm_orcl_schema"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.config.oracle.rhsm_orcl_schema.$error,
                                }"
                                class="form-control"
                                name=""
                                id=""
                              />
                              <div
                                v-if="
                                  submitted &&
                                    $v.config.oracle.rhsm_orcl_schema.$error
                                "
                                class="invalid-feedback"
                              >
                                <span
                                  v-if="
                                    !$v.config.oracle.rhsm_orcl_schema.required
                                  "
                                  >Champ Obligatoire.</span
                                >
                                <br />
                              </div>
                            </div>
                          </div>

                          <div class="col-md-4 mt-2">
                            <div class="form-grou">
                              <label for="">Nom de table (ou vue)</label>
                              <input
                                type="text"
                                v-model="config.oracle.rhsm_orcl_tableName"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.config.oracle.rhsm_orcl_tableName.$error,
                                }"
                                class="form-control"
                                name=""
                                id=""
                              />
                              <div
                                v-if="
                                  submitted &&
                                    $v.config.oracle.rhsm_orcl_tableName.$error
                                "
                                class="invalid-feedback"
                              >
                                <span
                                  v-if="
                                    !$v.config.oracle.rhsm_orcl_tableName
                                      .required
                                  "
                                  >Champ Obligatoire.</span
                                >
                                <br />
                              </div>
                            </div>
                          </div>

                          <div class="col-md-4 mt-2">
                            <a
                              href="javascript:void(0)"
                              @click="testOracleReshumConn"
                              >Tester la connexion</a
                            >
                          </div>

                          <div class="col-md-4 mt-2">
                            <div class="form-group">
                              <label for=""></label>
                              <button @click.prevent="syncEmployeesFromResHume" class="btn btn-success mt-2">Synchroniser la liste des employés</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />

                    <div class="row">
                      <div class="col-lg-12 ">
                        <h4 class="card-title mb-4">
                          Pointeuse Restaurant (sous Oracle)
                        </h4>
                        <div class="row">
                          <div class="col-md-4 mb-3">
                            <div class="form-grou">
                              <label for="">Nom de connection</label>
                              <input
                                type="text"
                                v-model="
                                  config.oracle.restReader_orcl_conn_name
                                "
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.config.oracle.restReader_orcl_conn_name
                                      .$error,
                                }"
                                class="form-control"
                                name=""
                                id=""
                              />
                              <div
                                v-if="
                                  submitted &&
                                    $v.config.oracle.restReader_orcl_conn_name
                                      .$error
                                "
                                class="invalid-feedback"
                              >
                                <span
                                  v-if="
                                    !$v.config.oracle.restReader_orcl_conn_name
                                      .required
                                  "
                                  >Champ Obligatoire.</span
                                >
                                <br />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-grou">
                              <label for="">Serveur</label>
                              <input
                                type="text"
                                v-model="config.oracle.restReader_orcl_host"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.config.oracle.restReader_orcl_host.$error,
                                }"
                                class="form-control"
                                name=""
                                id=""
                              />
                              <div
                                v-if="
                                  submitted &&
                                    $v.config.oracle.restReader_orcl_host.$error
                                "
                                class="invalid-feedback"
                              >
                                <span
                                  v-if="
                                    !$v.config.oracle.restReader_orcl_host
                                      .required
                                  "
                                  >Champ Obligatoire.</span
                                >
                                <br />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-grou">
                              <label for="">TNS</label>
                              <input
                                type="text"
                                v-model="config.oracle.restReader_orcl_tns"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.config.oracle.restReader_orcl_tns.$error,
                                }"
                                class="form-control"
                                name=""
                                id=""
                              />
                              <div
                                v-if="
                                  submitted &&
                                    $v.config.oracle.restReader_orcl_tns.$error
                                "
                                class="invalid-feedback"
                              >
                                <span
                                  v-if="
                                    !$v.config.oracle.restReader_orcl_tns
                                      .required
                                  "
                                  >Champ Obligatoire.</span
                                >
                                <br />
                              </div>
                            </div>
                          </div>
                                                  
                          <div class="col-md-4">
                            <div class="form-grou">
                              <label for="">Username</label>
                              <input
                                type="text"
                                v-model="config.oracle.restReader_orcl_username"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.config.oracle.restReader_orcl_username
                                      .$error,
                                }"
                                class="form-control"
                                name=""
                                id=""
                              />
                              <div
                                v-if="
                                  submitted &&
                                    $v.config.oracle.restReader_orcl_username
                                      .$error
                                "
                                class="invalid-feedback"
                              >
                                <span
                                  v-if="
                                    !$v.config.oracle.restReader_orcl_username
                                      .required
                                  "
                                  >Champ Obligatoire.</span
                                >
                                <br />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-grou">
                              <label for="">Password</label>
                              <input
                                type="password"
                                v-model="config.oracle.restReader_orcl_password"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.config.oracle.restReader_orcl_password
                                      .$error,
                                }"
                                class="form-control"
                                name=""
                                id=""
                              />
                              <div
                                v-if="
                                  submitted &&
                                    $v.config.oracle.restReader_orcl_password
                                      .$error
                                "
                                class="invalid-feedback"
                              >
                                <span
                                  v-if="
                                    !$v.config.oracle.restReader_orcl_password
                                      .required
                                  "
                                  >Champ Obligatoire.</span
                                >
                                <br />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-grou">
                              <label for="">Schema</label>
                              <input
                                type="text"
                                v-model="config.oracle.restReader_orcl_schema"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.config.oracle.restReader_orcl_schema
                                      .$error,
                                }"
                                class="form-control"
                                name=""
                                id=""
                              />
                              <div
                                v-if="
                                  submitted &&
                                    $v.config.oracle.restReader_orcl_schema
                                      .$error
                                "
                                class="invalid-feedback"
                              >
                                <span
                                  v-if="
                                    !$v.config.oracle.restReader_orcl_schema
                                      .required
                                  "
                                  >Champ Obligatoire.</span
                                >
                                <br />
                              </div>
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="">Nom de table (ou vue)</label>
                              <input
                                type="text"
                                v-model="config.oracle.restReader_orcl_tableName"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.config.oracle.restReader_orcl_tableName
                                      .$error,
                                }"
                                class="form-control"
                                name=""
                                id=""
                              />
                              <div
                                v-if="
                                  submitted &&
                                    $v.config.oracle.restReader_orcl_tableName
                                      .$error
                                "
                                class="invalid-feedback"
                              >
                                <span
                                  v-if="
                                    !$v.config.oracle.restReader_orcl_tableName
                                      .required
                                  "
                                  >Champ Obligatoire.</span
                                >
                                <br />
                              </div>
                            </div>
                          </div>

                          <div class="col-md-4 mt-2">
                            <a
                              href="javascript:void(0)"
                              @click="testOracleAccessConn"
                              >Tester la connexion</a
                            >
                          </div>
                        </div>
                      <!-- <hr/> -->
                      </div>


                      <!-- <div class="col-lg-12">
                        <h4>Horaires des services</h4>
                        <div class="row">
                          <div class="col-md-4">
                            <h6>Petit déjeuner</h6>
                          </div>
                          <div class="col-md-4">
                            <h6>Déjeuner</h6>
                          </div>
                          <div class="col-md-4">
                            <h6>Dinner</h6>
                          </div>
                        </div>
                      </div> -->
                    </div>
                    <hr />
                    <!-- Ordre de maintenance Maintenance -->
                    <div class="row">
                      <div class="col-md-12">
                        <button class="btn btn-primary float-right">
                          <i class="fas fa-save"></i> Enregistrer
                        </button>
                      </div>
                    </div>
                  </form>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
